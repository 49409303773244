import { useState } from "react";
import { CaisseType } from "../../types/data/Caisse";
import { CAISSETYPE } from "../../constants";
import { useTranslation } from "react-i18next";
import Cheque from "./Caisse/Cheque";
import Espece from "./Caisse/Espece";
import Virement from "./Caisse/Virement";
import Rubrique from "./Rubrique";
import Tpu from "./Caisse/Tpu";
import Versement from "./Caisse/Versement";

export default function Caisse(): React.JSX.Element {
  const { t } = useTranslation();

  const [caisseType, setCaisseType] = useState<CaisseType>("cheque");

  function handleDisplayForm(): React.JSX.Element | null {
    switch (caisseType) {
      case "cheque":
        return <Cheque />;
      case "bankTransfer":
        return <Virement />;
      case "cash":
        return <Espece />;
      case "tpe":
        return <Tpu />;
      case "bankDeposit":
        return <Versement />;
      default:
        return null;
    }
  }

  return (
    <article className="space-y-4 bg-white px-5 py-6">
      <section className="mx-auto flex w-fit items-center justify-center divide-x divide-primaryGreen overflow-hidden rounded-lg border border-primaryGreen">
        {CAISSETYPE.map((type, i) => (
          <div
            key={i}
            className={`cursor-pointer px-5 py-4 duration-150 hover:bg-primaryGreen hover:text-white ${caisseType === type ? "bg-primaryGreen font-medium text-white" : "bg-white text-primaryGreen"}`}
            onClick={() => setCaisseType(type)}
          >
            {t(type)}
          </div>
        ))}
      </section>
      <section className="grid grid-cols-2 gap-x-4">
        <Rubrique />
        {handleDisplayForm()}
      </section>
    </article>
  );
}
