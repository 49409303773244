import { createContext, useCallback, useContext, useState } from "react";
import { LoginRequest } from "../types/request/LoginRequest";
import { useLogin, useLogout, useOTPLink } from "../api/user";
import { ErrorResponse } from "../types/response";
import { UseMutationResult, useQueryClient } from "react-query";
import { UserData } from "../types/data/user";
import { LoginResponse } from "../types/response/LoginResponse";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { CaisseResponseBody } from "../types/response/CaisseResponse";

interface AuthContextType {
  userData: UserData;
  linkedWorkspaces: ILinkedWorkspace[] | null;
  selectedWorkspace: ILinkedWorkspace | null;
  permissions: Record<string, string>;
  isAuthentificated: boolean;
  login: UseMutationResult<LoginResponse, ErrorResponse, LoginRequest, unknown>;
  otpCode: UseMutationResult<void, ErrorResponse, LoginRequest, unknown>;
  selectedCaisse: CaisseResponseBody | null;
  logout: () => void;
  setSelectedWorkspace: (workspace: ILinkedWorkspace) => void;
  setSelectedCaisse: (caisse: CaisseResponseBody | null) => void;
  handleLoginSuccess: (data: LoginResponse) => void;
}

export interface ILinkedWorkspace {
  workspaceId: string;
  workspaceDescription: string;
  workspaceName: string;
}


export const AuthContext = createContext<AuthContextType | null>(null);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<UserData>(
    JSON.parse(
      localStorage.getItem("userData") ||
      JSON.stringify({
        email: "",
        phone: "",
        type: "guest",
        username: "",
      }),
    ) || {
      email: "",
      phone: "",
      type: "guest",
      username: "",
    },
  );
  const [permissions, setPermissions] = useState<Record<string, string>>(
    JSON.parse(localStorage.getItem("permissions") || "{}"),
  );
  const [linkedWorkspaces, setLinkedWorkspaces] = useState<ILinkedWorkspace[] | null>(
    JSON.parse(localStorage.getItem("linkedWorkspaces") || "[]") || null,
  );
  const [selectedWorkspace, setSelectedWorkspace] = useState<ILinkedWorkspace | null>(
    JSON.parse(localStorage.getItem("selectedWorkspace") || "null") || null,
  );
  const [isAuthentificated, setIsAuthentificated] = useState<boolean>(!!localStorage.getItem("token"));
  const [selectedCaisse, setSelectedCaisse] = useState<CaisseResponseBody | null>(
    JSON.parse(localStorage.getItem("selectedCaisse") || "null") || null,
  );

  const queryClient = useQueryClient();


  const login = useLogin({
    onSuccess: data => {
      handleLoginSuccess(data);
    },
  });

  const magicLink = useOTPLink();

  const { mutate: logout } = useLogout({
    onSuccess() {
      toast.success(t("logoutSuccess"));
    },
  });

  const { t } = useTranslation();

  const handleSetSelectedWorkspace = useCallback((workspace: ILinkedWorkspace) => {
    setSelectedWorkspace(workspace);
    localStorage.setItem("selectedWorkspace", JSON.stringify(workspace));
    queryClient.invalidateQueries("caisses");

  }, [queryClient]);

  const handleSetSelectedCaisse = useCallback((caisse: CaisseResponseBody | null) => {
    setSelectedCaisse(caisse);
    if (caisse)
      localStorage.setItem("selectedCaisse", JSON.stringify(caisse));
  }, []);

  const handleLoginSuccess = useCallback((data: LoginResponse) => {
    const { token, username, permissions, linkedWorkspaces, accountId } = data;

    setUser({
      username,
      email: "",
      phone: "",
      type: "admin",
      accountId
    });

    setPermissions(permissions);

    const sortedWorkspaces = linkedWorkspaces.sort((a, b) =>
      a.workspaceDescription.localeCompare(b.workspaceDescription),
    );

    setSelectedWorkspace(sortedWorkspaces[0] || null);
    setLinkedWorkspaces(sortedWorkspaces);

    localStorage.setItem("token", token);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        username,
        email: "",
        phone: "",
        type: "admin",
      }),
    );
    localStorage.setItem("permissions", JSON.stringify(permissions));
    localStorage.setItem("linkedWorkspaces", JSON.stringify(sortedWorkspaces));
    localStorage.setItem("selectedWorkspace", JSON.stringify(sortedWorkspaces[0] || null));
    setIsAuthentificated(true);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthentificated,
        userData: user,
        permissions,
        linkedWorkspaces,
        selectedWorkspace,
        login,
        selectedCaisse,
        otpCode: magicLink,
        logout: () => {
          logout(localStorage.getItem("token") || "");
          localStorage.removeItem("token");
          localStorage.removeItem("userData");
          localStorage.removeItem("permissions");
          localStorage.removeItem("linkedWorkspaces");
          localStorage.removeItem("selectedWorkspace");
          localStorage.removeItem("selectedCaisse");
          setUser({
            email: "",
            phone: "",
            type: "guest",
            username: "",
            accountId: "",
          });
          setPermissions({});
          setLinkedWorkspaces(null);
          setSelectedWorkspace(null);
          setIsAuthentificated(false);
          setSelectedCaisse(null);
        },
        setSelectedWorkspace: handleSetSelectedWorkspace,
        setSelectedCaisse: handleSetSelectedCaisse,
        handleLoginSuccess,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) throw new Error("AuthContext is not available. Make sure you wrap your component with AuthProvider.");

  return context;
}
