import { ErrorResponse, useSearchParams } from "react-router-dom";
import { axiosInstance } from "./axios";
import { ENDPOINTS } from "./endpoints";
import { begin, rowsPerPage } from "../utils/rowsPerPage";
import { useQuery, UseQueryOptions } from "react-query";
import { InvoiceResponseBody } from "../types/response/InvoiceResponse";
import { ListResponse } from "../types/response";

export function useGetInvoices(
  tenantId: string,
  workspaceId: string,
  options?: Omit<UseQueryOptions<ListResponse<InvoiceResponseBody>, ErrorResponse>, "queryKey" | "queryFn">,
) {
  const [searchParams] = useSearchParams();

  async function getInvoices() {
    const currentPage = Math.max(Number(searchParams.get("page")) || 1, 1);

    try {
      const response = await axiosInstance.get(
        ENDPOINTS.TENANTS_GET_TENANT_INVOICES_ENDPOINT + `${workspaceId}/${tenantId}`,
        {
          params: {
            begin: begin(currentPage, rowsPerPage),
            count: rowsPerPage,
          },
        },
      );

      return response.data;
    } catch (error: any) {
      console.log(error?.response?.data);
      throw error?.response?.data;
    }
  }

  return useQuery<ListResponse<InvoiceResponseBody>, ErrorResponse>(
    ["invoices", searchParams.get("page"), tenantId, workspaceId],
    getInvoices,
    {
      ...options,
    },
  );
}
