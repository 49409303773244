import { useFormik } from "formik";
import InputField from "../../../components/Inputs/InputField";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import { createEspeceValidator } from "./validator";
import { CaisseEspeceResponseBody } from "../../../types/request/CaisseRequest";
import { useCreateCashTransaction, usePostQuote } from "../../../api/payments";
import { CAISSETYPE } from "../../../constants";
import Spinner from "../../../components/ui/Spinner";
import { useCaisse } from "./Provider";
import { useEffect, useRef, useState } from "react";
import { TextMoneyFormat } from "../../../utils/TextMoneyFormat";
import toast from "react-hot-toast";
import { formatCreatedAtDate } from "../../../utils/formatDates";
import ConfirmPopup from "./ConfirmPopup";

export default function Espece(): React.JSX.Element {
  const {
    maxAllowedNumberOfMonthsToPay,
    minAllowedNumberOfMonthsToPay,
    tenantId,
    setTransactionRefrence,
    transactionRefrence,
    lastPaidDate,
    numberOfMonthsToPay,
    rubriques
  } = useCaisse();

  const { isLoading: isLoadingPayment, mutate: createEspeceTransaction } = useCreateCashTransaction();

  const {
    isLoading: isLoadingQuote,
    data,
    mutate: getQuote,
  } = usePostQuote(tenantId, {
    onSuccess(data) {
      setTransactionRefrence(data.transactionReference);
    },
  });

  const isLoading: boolean = isLoadingPayment || isLoadingQuote;

  const { t } = useTranslation();
  const handedOverRef = useRef<number>();
  const [confirmPopup, setConfirmPopup] = useState<boolean>(false);

  const initialValues: CaisseEspeceResponseBody = {
    handedOver: NaN,
    price: NaN,
    months: numberOfMonthsToPay || 1,
  };

  const formik = useFormik<CaisseEspeceResponseBody>({
    initialValues,
    validationSchema: createEspeceValidator(t, minAllowedNumberOfMonthsToPay, maxAllowedNumberOfMonthsToPay),
    onSubmit: values => {
      if (!transactionRefrence || !confirmPopup) {
        setConfirmPopup(true);
        return;
      }
      createEspeceTransaction({
        ...values,
        type: CAISSETYPE[2],
        tenantId: tenantId,
        numberOfMonths: values.months,
        transactionReference: transactionRefrence,
        rubriques
      }, {
        onSuccess() {
          toast.success(t("paymentSuccess"));
          setConfirmPopup(false);
        },
      });
    },
  });

  useEffect(() => {
    if (
      formik.values.months >= minAllowedNumberOfMonthsToPay &&
      formik.values.months <= maxAllowedNumberOfMonthsToPay
    ) {
      getQuote({
        type: CAISSETYPE[0],
        numberOfMonths: formik.values.months,
        rubriques
      });
    }
  }, [formik.values.months, minAllowedNumberOfMonthsToPay, maxAllowedNumberOfMonthsToPay, getQuote, rubriques]);

  useEffect(() => {
    if (data?.totalQuote && formik.values.price) {
      const handedOver = Math.round(formik.values.price - data.totalQuote);

      if (handedOver === handedOverRef.current) return;

      handedOverRef.current = handedOver;
      formik.setFieldValue("handedOver", handedOver);
    }
  }, [data?.totalQuote, formik.values.price, formik]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="grid grid-cols-2 gap-8 gap-y-6 h-max px-10 pt-2 lg:px-16"
    >
      <InputField
        label="monthsToPay"
        name="months"
        onChange={formik.handleChange}
        placeholder=""
        type="number"
        value={formik.values.months}
        error={formik.errors.months}
      />

      <section className="flex items-center gap-2">
        <span className="font-medium text-neutral-800">{t("period")} :</span>
        <span className="font-semibold text-primaryGreen">
          {formatCreatedAtDate(t, lastPaidDate)}
          {lastPaidDate &&
            formik.values.months > 1 &&
            " - " +
            formatCreatedAtDate(
              t,
              new Date(new Date(lastPaidDate).setMonth(new Date(lastPaidDate).getMonth() + formik.values.months - 1))
                .toISOString()
                .replace(".000Z", ""),
            )}
        </span>
      </section>

      <InputField
        label="amountRecieved"
        name="price"
        onChange={formik.handleChange}
        placeholder=""
        type="number"
        value={formik.values.price}
        error={formik.errors.price}
      />



      <InputField
        label="handedOver"
        name="handedOver"
        onChange={formik.handleChange}
        placeholder=""
        type="number"
        value={formik.values.handedOver}
        error={formik.errors.handedOver}
        readonly
      />

      <section className="col-span-2 flex gap-6  items-center justify-center flex-wrap">
        <div className="flex items-center gap-2">
          <span className="font-medium text-neutral-800">{t("tva")} :</span>
          <span className="font-semibold text-primaryGreen">{data && data.tva >= 0 ? TextMoneyFormat(data.tva, t) : t("N/A")}</span>
        </div>
        <div className="flex items-center gap-2">
          <span className="font-medium text-neutral-800">{t("ht")} :</span>
          <span className="font-semibold text-primaryGreen">{data && data.ht >= 0 ? TextMoneyFormat(data.ht, t) : t("N/A")}</span>
        </div>
        <div className="flex items-center gap-2">
          <span className="font-medium text-neutral-800">{t("penaltyAmount")} :</span>
          <span className="font-semibold text-primaryGreen">{data && data.penalty >= 0 ? TextMoneyFormat(data.penalty, t) : t("N/A")}</span>
        </div>
      </section>

      <section className=" flex items-center justify-center gap-4 col-span-2">
        <div className="flex gap-4">
          <span className="text-2xl font-semibold">{t("netAPay")}</span>
          <span className="text-2xl font-semibold text-red-500">
            {isLoadingQuote ? <Spinner /> : TextMoneyFormat(data?.totalQuote || 0, t)}
          </span>
        </div>
        <PrimaryButton disabled={isLoading}>{isLoading ? <Spinner /> : t("saveRecu")}</PrimaryButton>
      </section>

      {confirmPopup && formik.isValid && (
        <ConfirmPopup
          isLoading={isLoading}
          onClose={() => setConfirmPopup(false)}
          totalQuote={data?.totalQuote || 0}
        />
      )}
    </form>
  );
}
