import { TFunction } from "i18next";
import Yup from "../../utils/customYup";
import { RubriqueRequestBody } from "../../types/request/RubriqueRequest";

export function createRegisterRubriqueSchema(t: TFunction): Yup.Schema<RubriqueRequestBody> {
    return Yup.object({
        workspaceId: Yup.string().required(t("required")),
        registryId: Yup.string().required(t("required")),
        tenantId: Yup.string().required(t("required")),
        rubriqueId: Yup.string().required(t("required")),

        amount: Yup.number().integer(t("integer")).typeError(t("required")).required(t("required")).min(1, t("minValue", { value: 1 })),
    });
}