import { useTranslation } from "react-i18next";
import { useGetCaisses } from "../../api/caisses";
import { useAuth } from "../../contexts/Auth";

export default function CaisseSelector(): React.JSX.Element {
    const { t } = useTranslation();

    const { data } = useGetCaisses({
        onSuccess: (data) => {
            if (selectedCaisse && data.response.length <= 0) setSelectedCaisse(null);

            if ((!selectedCaisse || !data.response.includes(selectedCaisse)) && data.response.length > 0) setSelectedCaisse(data.response[0]);
        }
    });
    const { setSelectedCaisse, selectedCaisse } = useAuth();

    const { response: caisses } = data || {};

    return (
        <select
            className="h-12 min-w-24 w-full cursor-pointer rounded-lg border px-4 text-base font-bold text-primaryBlack outline-none"
            id="workspace"
            name="workspace"
            value={selectedCaisse?.name || ""}
            onChange={e => {
                const caisseId = e.target.value;
                const selected = caisses?.find(ws => ws.id === caisseId);

                if (selected) setSelectedCaisse(selected);
                else console.error("Caisse not found");
            }}
        >
            <option value="" disabled>
                {t("selectCashRegister")}
            </option>
            {caisses &&
                caisses.map(option => (
                    <option
                        key={option.id}
                        value={option.id}
                    >
                        {option.name}
                    </option>
                ))}
        </select>
    );
}