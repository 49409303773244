import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "react-query";
import { ErrorResponse, ListResponse } from "../types/response";
import { CaisseResponseBody } from "../types/response/CaisseResponse";
import { axiosInstance } from "./axios";
import { ENDPOINTS } from "./endpoints";
import { begin } from "../utils/rowsPerPage";
import { StopCaisseRequestBody } from "../types/request/CaisseRequest";
import { useAuth } from "../contexts/Auth";
import toast from "react-hot-toast";
import SubmitResponseHandler from "../components/SubmitResponseHandler";

export function useGetCaisses(
    options?: Omit<UseQueryOptions<ListResponse<CaisseResponseBody>, ErrorResponse>, "queryKey" | "queryFn">
) {
    const { workspaceId } = useAuth().selectedWorkspace || {};

    async function getCaisses() {
        try {
            const response = await axiosInstance.get(ENDPOINTS.CASH_REGISTER_GET_LIST_ENDPOINT + workspaceId, {
                params: {
                    begin: begin(0, 9999),
                    count: 9999
                }
            });
            return response.data;
        } catch (error: any) {
            throw error.response.data;
        }
    }

    return useQuery<ListResponse<CaisseResponseBody>, ErrorResponse>(["caisses", workspaceId], getCaisses, {
        ...options,
    });
}

export function useStopCaisse(
    options?: Omit<UseMutationOptions<void, ErrorResponse, StopCaisseRequestBody>, "mutationKey" | "mutationFn">

) {

    async function stopCaisse(values: StopCaisseRequestBody) {
        try {
            const response = await axiosInstance.post(ENDPOINTS.CASH_REGISTER_STOP_ENDPOINT, values);

            return response.data;
        } catch (error: any) {
            throw error.response.data;
        }
    }


    return useMutation<void, ErrorResponse, StopCaisseRequestBody>(stopCaisse, {
        onError: (error) => {
            toast.error(<SubmitResponseHandler {...error} />);
        },
        ...options,
    });
}