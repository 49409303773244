import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/Auth";
import { ReactComponent as UserIcon } from "../../assets/icons/userIcon.svg";
import LogoutBtn from "../LogoutBtn";
import { useTranslation } from "react-i18next";
import StopCaisse from "./StopCaisse";

export default function ProfileModal(): React.JSX.Element {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isStopCaisseOpen, setIsStopCaisseOpen] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const { username } = useAuth().userData;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative">
      <UserIcon
        onClick={() => setIsOpen(prev => !prev)}
        className="cursor-pointer"
      />
      {isOpen && (
        <div
          ref={modalRef}
          className={`dropdownArrow absolute end-0 top-16 w-72 divide-y divide-primaryBlack rounded-lg border bg-white px-6 py-4 shadow-lg *:px-4 after:absolute after:-top-1 after:end-5 after:size-2 after:rotate-45 after:border-l after:border-t after:border-l-gray-200 after:border-t-gray-200`}
        >
          <section className="flex items-center gap-2 pb-4">
            <UserIcon className="size-10" />
            <span>{username}</span>
          </section>


          <div className="flex flex-col gap-y-4 pt-2">
            <section
              className="cursor-pointer hover:text-primaryGreen font-semibold"
              onClick={() => setIsStopCaisseOpen(true)}
            >
              {t("stopCaisse")}
            </section>
            <section >
              <LogoutBtn />
            </section>
          </div>

        </div>
      )}

      {isStopCaisseOpen && (
        <StopCaisse
          onClose={() => setIsStopCaisseOpen(false)}
        />
      )}
    </div>
  );
}
