import { useTranslation } from "react-i18next";
import { StopCaisseRequestBody } from "../../types/request/CaisseRequest";
import { useFormik } from "formik";
import { useStopCaisse } from "../../api/caisses";
import usePopup from "../../hooks/usePopup";
import InputCheckbox from "../Inputs/InputCheckbox";
import PrimaryButton from "../Buttons/PrimaryButton";
import Spinner from "../ui/Spinner";
import toast from "react-hot-toast";
import { useAuth } from "../../contexts/Auth";

interface StopCaisseProps {
    onClose: () => void;
}

export default function StopCaisse({ onClose }: StopCaisseProps): React.JSX.Element {
    usePopup();
    const { t } = useTranslation();
    const { selectedCaisse, selectedWorkspace, userData } = useAuth();
    const { isLoading, mutate: stopCaisse } = useStopCaisse();

    const initialValues: StopCaisseRequestBody = {
        isBank: false,
        isCCP: false,
        registryId: selectedCaisse?.id || "",
        userInfoId: userData.accountId,
        workspaceId: selectedWorkspace?.workspaceId || "",
    };

    const formik = useFormik<StopCaisseRequestBody>({
        initialValues,
        onSubmit: (values) => {
            stopCaisse(values, {
                onSuccess: () => {
                    toast.success(t("stopCaisseSuccess"));
                    onClose();
                }
            });
        },
    });

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-100/10">
            <div className="relative z-10 flex w-[500px] flex-col items-center gap-y-6 rounded-lg bg-white p-8 shadow-lg">
                <div className="flex justify-end">
                    <button
                        className="absolute end-4 top-3 text-lg font-bold text-gray-500 hover:text-red-500"
                        onClick={onClose}
                    >
                        X
                    </button>
                    <h1 className="text-center text-destructive-500 text-2xl font-semibold">{t("stopCaisse")}</h1>
                </div>
                <form className="w-full space-y-8" onSubmit={formik.handleSubmit}>
                    <section className="space-y-4">
                        <div className="font-medium text-lg">{t("versement")}:</div>
                        <div className="flex items-center gap-4 justify-center">
                            <InputCheckbox
                                label="CCP"
                                name="isCCP"
                                onChange={formik.handleChange}
                                value={formik.values.isCCP}
                            />
                            <InputCheckbox
                                label="Bank"
                                name="isBank"
                                onChange={formik.handleChange}
                                value={formik.values.isBank}
                            />
                        </div>
                    </section>
                    <section className="flex justify-center gap-4">
                        <PrimaryButton
                            type="reset"
                            onClick={onClose}
                            className="!bg-destructive-500"
                        >
                            {t("cancel")}
                        </PrimaryButton>
                        <PrimaryButton
                            type="submit"
                            disabled={isLoading}
                        >
                            {isLoading ? <Spinner /> : t("confirm")}
                        </PrimaryButton>
                    </section>
                </form>
            </div>
            <div
                className="absolute inset-0 cursor-pointer bg-slate-900 opacity-40"
                onClick={onClose}
            />
        </div>
    );
}